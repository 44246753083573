var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pvhFactory" },
    [
      _c(
        "basic-container",
        [
          _c("div", { staticClass: "layTop" }, [
            _c(
              "div",
              {
                staticClass: "Title",
                staticStyle: { "margin-bottom": "20px" }
              },
              [_vm._v(_vm._s(_vm.$t("VENDORSUPPLIER")))]
            )
          ]),
          [
            _c("avue-crud", {
              ref: "crud",
              attrs: {
                option: _vm.setData.tableOpt,
                data: _vm.tableData,
                "row-style": _vm.rowStyle,
                page: _vm.page
              },
              on: {
                "update:page": function($event) {
                  _vm.page = $event
                },
                "current-change": _vm.currentChange
              },
              model: {
                value: _vm.obj,
                callback: function($$v) {
                  _vm.obj = $$v
                },
                expression: "obj"
              }
            })
          ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }