<template>
    <div class="pvhFactory">
        <basic-container>
            <div class="layTop">
                <div class="Title" style="margin-bottom:20px;">{{$t('VENDORSUPPLIER')}}</div>
            </div>
            <template>
                <avue-crud ref="crud" v-model="obj" :option="setData.tableOpt" :data="tableData" :row-style="rowStyle" :page.sync="page" @current-change="currentChange">

                </avue-crud>
            </template>
        </basic-container>
    </div>
</template>
<style scoped>
    .dataicon i {
        display: none;
    }

    .othChe .el-checkbox__label {
        color: #409eff !important;
    }
</style>
<script>
    import { SupplierVendorList } from "@/api/supplierCer";
    import { TracingDropDownList } from "@/api/unified";
    export default {
        data() {
            return {
                obj: {},
                tableData: [],
                vendorvectionData: [],//供应商类别
                BrandBusinessData:[],
                page: {
                    currentPage: 1,
                    total: 0,
                    layout: "total,pager,prev,next",
                    background: true,
                    pageSize: 10
                },
            }
        },
        computed: {
            setData() {
                return {
                    tableOpt: {
                        page: true,
                        refreshBtn: false,
                        delBtn: false,
                        emptyText: this.$t('No Data'),
                        menuTitle: this.$t('OPERATION'),
                        menuWidth: 140,
                        align: 'center',
                        columnBtn: false,
                        simplePage: false,
                        editTitle: this.$t('Edit'),
                        addTitle: this.$t('Add'),
                        addBtn: false,
                        editBtn: false,
                        header: true,
                        saveBtn: false,
                        updateBtn: false,
                        cancelBtn: false,
                        menu: false,
                        labelPosition: 'top',
                        column: [
                            {
                                label: this.$t('SupplierNickname'),
                                prop: 'certifiedName',
                                placeholder: ' ',

                            },
                            {
                                label: this.$t('CertifiedCode'),
                                prop: 'code',
                                placeholder: ' ',
                            },
                            {
                                label: this.$t('BelongingCategory'),
                                prop: 'vendorSections',
                                type: "checkbox",
                                dicData: this.vendorvectionData,
                                placeholder: ' ',
                            },
                            {
                                label: this.$t('BRAND DEALER'),
                                prop: 'brandBusinessId',
                                type: "checkbox",
                                dicData: this.BrandBusinessData,
                                placeholder: ' ',
                            },
                 
                        ]
                    },
                }
            }
        },
        created() {
            TracingDropDownList('all_VendorSectionList').then(res => {
                this.vendorvectionData = res.data;
            });
            TracingDropDownList('all_BrandBusinessList').then(res => {
                this.BrandBusinessData = res.data;
            });
            this.GetList();
        },
        methods: {
            rowStyle({ rowIndex }) {
                if (rowIndex % 2 === 0) {
                    return {
                        backgroundColor: '#F8F8F8'
                    }
                }
            },//切换分页
            currentChange(val) {
                this.page.currentPage = val;
                this.GetList();
            },//搜索
            async GetList() {
                SupplierVendorList("", "", "", this.page.currentPage, this.page.pageSize).then(res => {
                    console.log("res", res);
                    this.tableData = res.data.certifiedVendors;
                    this.page.total = res.data.totalCount;
                    this.page.pageSize = res.data.pageSize;
                }).catch(erro => {
                    console.log(erro);
                })
            },
        }
    }
</script>