var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("basic-container", [
        _vm.$route.query.src
          ? _c("iframe", {
              ref: "iframe",
              staticClass: "iframe",
              attrs: {
                src:
                  _vm.$route.query.src +
                  "&culture=" +
                  this.$i18n.locale +
                  "&ui-culture=" +
                  this.$i18n.locale
              }
            })
          : _c("iframe", {
              ref: "iframe",
              staticClass: "iframe",
              attrs: { src: _vm.urlPath }
            })
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }