<template>
    <div>
        <basic-container>
            <el-table :data="data" style="width: 100%">
                <el-table-column prop="productCode" label="产品编码"></el-table-column>
                <el-table-column prop="productName" label="产品名称"></el-table-column>
                <el-table-column prop="batchNo" label="批次号"></el-table-column>
                <el-table-column prop="sampleSize" label="样本大小"></el-table-column>
                <el-table-column prop="parameterStandard" label="标准"></el-table-column>
                <el-table-column prop="inspector" label="检验人"></el-table-column>
                <el-table-column prop="inspectionMaterial" label="检验数据"></el-table-column>
                <el-table-column prop="complianceWithStandards" label="是否符合标准"></el-table-column>
                <el-table-column prop="mpsCreateTime" label="检验时间"></el-table-column>
            </el-table>
            <div class="block" style="float: right; margin: 30px;">
                <div class="block" style="float: right; margin: 30px;">
                    <el-pagination @size-change="handleSizeChange"
                                   @current-change="handleCurrentChange"
                                   :current-page="page.currentPage"
                                   :page-sizes="[10, 20, 50, 40,50,100]"
                                   :page-size="page.pageSize"
                                   layout="total, sizes, prev, pager, next, jumper"
                                   :total="page.total">
                    </el-pagination>
                </div>
                <div style="text-align:right;padding:50px 0;">
                    <el-button @click="resetForm">{{ $t('Cancel') }}</el-button>
                </div>
            </div>
        </basic-container>
    </div>
</template>
<script>
    import {list} from "@/api/materialComparison";

    export default {
        data() {
            return {
                //路由传递信息
                routeSign: '', routeCode: '',
                data: [],
                page: {
                    pageSize: 20,
                    currentPage: 1,
                    total: 0
                },
            }
        },
        mounted() {
            this.init();
        },
        watch: {
            $route() {
                this.init()
            }
        },
        methods: {
            //初始加载数据
            init() {
                this.routeSign = this.$route.query.sign;
                this.routeCode = this.$route.query.productCode;
                const xx = {
                    pageNo: this.page.currentPage,
                    pageSize: this.page.pageSize,
                    total: this.page.total,
                    productCode: this.routeCode
                }
                console.log('xx', xx)
                list(xx).then(res => {
                    console.log(res.data.data)
                    debugger
                    this.data = res.data.data.items.map((v) => {
                        return {
                            ...v,
                            complianceWithStandards: v.complianceWithStandards === null ? "" : v.complianceWithStandards === 1 ? "合格" : "不合格"
                        }
                    });
                    this.page.total = res.data.data.total;
                    console.log('this.page.total', this.page.total)
                    console.log(this.data)
                })
            },
            //取消按钮
            resetForm() {
                this.$store.getters.tagList.splice(this.$store.getters.tagList.findIndex(item => item.value === this.$route.fullPath), 1);
                this.$router.push({path: '/quality/materialComparison'});
            },
            //添加行号
            tableRowClassName({row, rowIndex}) {
                // 把每一行的索引放进row
                row.index = rowIndex
            },
            //列头加星号
            starAdd(obj) {
                if (obj.columnIndex === 2 || obj.columnIndex === 3 || obj.columnIndex === 7 || obj.columnIndex === 8 || obj.columnIndex === 9 || obj.columnIndex === 11) {
                    return 'star';
                }
            },
            handleSizeChange(size) {
                this.page.pageSize = size;
                this.init();
            },
            handleCurrentChange(page) {
                this.page.currentPage = page;
                this.init(this.page);
                console.log('this.page.currentPage', this.page.currentPage)
            },
        }
    }
</script>