<template>
    <div>
        <basic-container>
            <avue-crud :option="tableOption"
                       :data="tableData"
                       :table-loading="tableLoading"
                       :page.sync="page"
                       ref="crud"
                       @current-change="currentChange"
                       @size-change="sizeChange"
                       @selection-change="selectionChange"
                       @refresh-change="refreshChange"
                       @row-save="handleSave"
                       @row-update="handleUpdate"
                       @row-del="handleDel">
                <template slot="status" slot-scope="scope">
                    <el-switch v-model="scope.row.status" :inactive-value="1" :active-value="0"

                               @change="change(scope.row)">
                    </el-switch>
                </template>
                <template slot="menuLeft">
                    <importBtn
                            :outApi="this.proApis.DEPARTMENTEXPORTGROUP"
                            :innerAPI="this.proApis.DEPARTMENTIMPORT"
                            :API="this.proApis.DEPARTMENTIMPORTTEMPLATE"
                            @refresh="refreshData"
                    ></importBtn>
                </template>
                <template slot="menuRight">
                    <el-select v-model="status" :placeholder="$t('STATE')" size="small"
                               style="width:100px !important;marginRight: 5px;">
                        <el-option :label="$t('NORMAL')" value="0">{{$t('NORMAL')}}</el-option>
                        <el-option :label="$t('DISABLE')" value="1">{{$t('DISABLE')}}</el-option>
                    </el-select>
                    <el-input v-model="nameOrIdLike" :placeholder="$t('TEAM NAME')"
                              style="display:inlie-block;width:200px;marginRight: 5px;" size="small"></el-input>
                </template>
                <template slot="menu" slot-scope='scope'>
                    <div style="height:30px;" v-if="scope.row.status == 0"/>
                    <el-button icon="el-icon-data-analysis" :size="scope.size" :type="scope.type"
                               @click="goReport(scope.row)">查看报表
                    </el-button>
                </template>
            </avue-crud>
        </basic-container>
    </div>
</template>
<script>
    import {list, changeStatus, add, edit, remove} from "@/api/timp"
    import importBtn from "@/components/importExport/importBtn";
    import {wklnList} from "@/api/config";

    export default {
        components: {
            importBtn
        },
        data() {
            return {
                tableLoading: false,
                nameOrIdLike: "",
                status: "0",
                page: {
                    pageSize: 20,
                    pagerCount: 5,
                    total: 0,
                    pageCurrent: 1
                },
                disabled: true,
                tableData: [{name: "甲班"}],
                tableOption: {
                    delBtn: true,
                    menuWidth: 200,
                    selection: true,
                    tip: false,
                    columnBtn: false,
                    dialogFullscreen: true,
                    addBtnText: this.$t('ADD'),
                    delBtnText: this.$t('DELETE'),
                    editBtnText: this.$t('Edit'),
                    emptyText: this.$t('No Data'),
                    menuTitle: this.$t('OPERATION'),
                    column: [{
                        label: this.$t('BELONGING WORKSHOP'),
                        type: "select",
                        hide: true,
                        dicUrl: this.businessUrl + "department/selectWorkShopName?type=1",
                        prop: "parentId",
                        props: {
                            label: "name",
                            value: "id"
                        },
                        rules: [{
                            required: true,
                            message: this.$t('PLEASE ENTER A NAME'),
                            trigger: "blur"
                        }]
                    }, {
                        label: this.$t('TEAM NAME'),
                        prop: "name",
                        rules: [{
                            required: true,
                            message: this.$t('PLEASE ENTER A NAME'),
                            trigger: "blur"
                        }]
                    },
                        {
                            label: this.$t('BELONGING WORKSHOP'),
                            prop: "parentName",
                            display: false,
                            rules: [{
                                required: true,
                                message: this.$t('PLEASE ENTER BELONGING WORKSHOP'),
                                trigger: "blur"
                            }]
                        },
                        // {
                        //     label: this.$t('BELONGING WORKING PROCEDURE'),
                        //     prop: "processName",
                        //     type: "select",
                        //     dicData: [],
                        //     props: {
                        //         label: "name",
                        //         value: "id"
                        //     },
                        //     rules: [{
                        //         required: true,
                        //         message: this.$t('PLEASE ENTER BELONGING OPERATION'),
                        //         trigger: "blur"
                        //     }],
                        //     formatter: function (row) {
                        //         return row.processName;
                        //     }
                        // },
                        //},{
                        //  label: "编码",
                        //  prop: "leaderUserid",
                        //  rules: {
                        //    required: true,
                        //    message: "请输入编码",
                        //    trigger: "blur"
                        //  }
                        {
                            label: this.$t('STATE'),
                            prop: "status",
                            type: "switch",
                            slot: true,
                            dicData: [{
                                label: this.$t('DISABLE'),
                                value: 1
                            }, {
                                label: this.$t('ENABLE'),
                                value: 0
                            }],
                            rules: [{
                                required: true,
                                message: this.$t('PLEASE ENTER THE STATUS'),
                                trigger: "blur"
                            }]
                        }, {
                        label: this.$t('PHYSICAL UNION NUMBER'),
                        prop: "iotCode",
                      }]
                }
            }
        },
        created() {
            // wklnList().then(res => {
            //     this.tableOption.column[3].dicData = res.data;
            // });
            this.list();
        },
        methods: {
            goReport(item) {
                console.log(item)
                this.$router.push({
                    path: '/searchDashboard/reportStyle',
                    query: {type: 'timp', id: item.id, parentId: item.parentId}
                })
            },
            currentChange(pageCurrent) {
                this.page.pageCurrent = pageCurrent;
                this.list();
            },
            sizeChange(pageSize) {
                this.page.pageCurrent = 1;
                this.page.pageSize = pageSize;
                this.list();
            },
            refreshData() {
                this.list();
            },
            // getPermission(key, row, index) {
            //   if (key==='delBtn' && row.status === 0 ) {
            //     return false;
            //   } else if (key==='editBtn' && row.status === 0 ){
            //     return false;
            //   }
            //   return true;
            // },
            handleDel(row) {
                this.$confirm(this.$t('IsDel'), this.$t('Tips'), {
                    confirmButtonText: this.$t('Yes'),
                    cancelButtonText: this.$t('Cancel'),
                    type: 'warning'
                }).then(() => {
                    remove(row.id).then(res => {
                        if (res.data.code == "BUSINESS.0005") {
                            this.$message({
                                type: "error",
                                message: this.$t('FAILED TO DELETE. PLEASE ENSURE THAT THE SUBORDINATE ORGANIZATION HAS BEEN DELETED FIRST')
                            });
                        } else {
                            if (res.data.code == "0000") {
                                this.$message({
                                    message: this.$t('DELETE SUCCEEDED, REFRESH DATA'),
                                    type: "success"
                                });
                            }
                        }
                        this.list();
                    })
                })
            },
            handleUpdate(row, index, done) {
                edit(row).then((res) => {
                    if (res.data.code == "0000") {
                        this.$message({
                            message: "修改成功",
                            type: "success"
                        });
                    }
                    this.list();
                    done();
                })
            },
            handleSave(row, done) {
                add(row).then((res) => {
                    if (res.data.code == "0000") {
                        this.$message({
                            message: "新增成功",
                            type: "success"
                        });
                    }
                    this.list();
                    done();
                })
            },
            change(row) {
                changeStatus(row).then(() => {
                    if (res.data.code == "0000") {
                        this.$message({
                            message: "删除成功",
                            type: "success"
                        });
                    }
                });
            },
            selectionChange(list) {
                console.log(list);
                list != "" ? this.disabled = false : this.disabled = true;
            },
            refreshChange() {
                this.list();
            },
            list() {
                list(this.page.pageCurrent, this.page.pageSize, this.nameOrIdLike, this.status).then(res => {
                    console.log('res', res);
                    if (res.data.data == null) {
                        this.tableData = [];
                        this.page.total = 0;
                    } else {
                        this.page.total = res.data.data.total;
                        this.tableData = res.data.data.items;
                        console.log('this.tableData', this.tableData);
                        // this.tableData.forEach(v => {
                        //     this.status = v.status;
                        // })
                    }
                })
            },
            envText: function () {
                return this.env
            }
        }
    }
</script>
<style scoped>
    .el-select-dropdown .el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
        padding-left: 10px;
    }
</style>
