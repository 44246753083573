var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "basic-container",
        [
          _c(
            "div",
            [
              _c("span", [_vm._v(_vm._s(_vm.$t("UserName")) + "：")]),
              _c("el-input", {
                staticStyle: { width: "200px", height: "50px" },
                attrs: { readonly: "", placeholder: "" },
                model: {
                  value: _vm.$route.query.userName,
                  callback: function($$v) {
                    _vm.$set(_vm.$route.query, "userName", $$v)
                  },
                  expression: "$route.query.userName"
                }
              }),
              _c("el-input", {
                staticStyle: {
                  width: "200px",
                  height: "50px",
                  display: "none"
                },
                attrs: { placeholder: "" },
                model: {
                  value: _vm.userId,
                  callback: function($$v) {
                    _vm.userId = $$v
                  },
                  expression: "userId"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { marginTop: "20px", display: "flex" } },
            [
              _vm._v("\n\t\t\t" + _vm._s(_vm.$t("Role")) + "：\n\t\t\t"),
              _c("el-tree", {
                ref: "treeData",
                attrs: {
                  slot: "reference",
                  data: _vm.data,
                  "show-checkbox": "",
                  "node-key": "id",
                  "highlight-current": "",
                  props: _vm.defaultProps
                },
                on: {
                  check: _vm.clickCheck,
                  setCheckedKeys: _vm.setCheckedKeys,
                  "node-collapse": _vm.nodeCollapse
                },
                slot: "reference"
              })
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticStyle: { marginTop: "20px", marginLeft: "50px" },
              attrs: { type: "info" },
              on: { click: _vm.saveUserRole }
            },
            [_vm._v(_vm._s(_vm.$t("save")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }