var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pvhFactory" },
    [
      _c(
        "basic-container",
        [
          _c(
            "avue-crud",
            {
              attrs: {
                data: _vm.data,
                option: _vm.setData.option,
                page: _vm.page
              },
              on: {
                "row-save": _vm.rowSave,
                "update:page": function($event) {
                  _vm.page = $event
                },
                "current-change": _vm.currentChange,
                "refresh-change": _vm.refreshChange
              },
              model: {
                value: _vm.obj,
                callback: function($$v) {
                  _vm.obj = $$v
                },
                expression: "obj"
              }
            },
            [
              _c(
                "template",
                { slot: "menuRight" },
                [
                  _c("el-input", {
                    staticStyle: {
                      width: "200px !important",
                      marginRight: "5px"
                    },
                    attrs: {
                      placeholder: "请输入名称",
                      clearable: "",
                      size: "small"
                    },
                    model: {
                      value: _vm.name,
                      callback: function($$v) {
                        _vm.name = typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "name"
                    }
                  }),
                  _c("el-input", {
                    staticStyle: {
                      width: "200px !important",
                      marginRight: "5px"
                    },
                    attrs: {
                      placeholder: "请输入编码",
                      clearable: "",
                      size: "small"
                    },
                    model: {
                      value: _vm.code,
                      callback: function($$v) {
                        _vm.code = typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "code"
                    }
                  })
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }