<template>
    <div class="pvhFactory">
        <basic-container>
            <div class="layTop">
                <div class="Title" style="margin-bottom:10px;">{{$t('Business Sales Order')}}</div>
            </div>
            <template>
                <el-row :gutter="20" class="topFromSearch">
                    <el-col :xs="24" :sm="17" :md="17" :lg="19" :xl="20" class="fromOutDiv">
                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                            <span>{{$t('Business No In')}}:</span>
                            <el-input v-model="businessnoinVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                        </el-col>
                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                            <span>{{$t('Business No Out')}}:</span>
                            <el-input v-model="businessnooutVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                        </el-col>
                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                            <span>{{$t('BEINGTIME')}}:</span>
                            <el-date-picker class="dataicon" v-model.trim="beginTimeVal"
                                            type="date" @focus="focusDiv($event)" @blur="blurDiv($event)" prefix-icon="" style="width:100%;">
                            </el-date-picker>
                        </el-col>
                        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                            <span>{{$t('ENDTIME')}}:</span>
                            <el-date-picker class="dataicon" v-model.trim="endTimeVal"
                                            type="date" @focus="focusDiv($event)" @blur="blurDiv($event)" prefix-icon="" style="width:100%;">
                            </el-date-picker>
                        </el-col>
                    </el-col>
                    <el-col :xs="24" :sm="7" :md="7" :lg="5" :xl="4" style="padding-top:10px;">
                        <el-button type="primary" size="small" plain @click="search">{{$t('SEARCH')}}</el-button>
                    </el-col>
                </el-row>
                <avue-crud class="pvhTable"
                           ref="crud"
                           :option="tableOption"
                           :data="tableData"
                           :row-style="rowStyle"
                           :page="page"
                           @current-change="currentChange"
                           @size-change="sizeChange">
                    <template slot-scope="{row,index,size,type}" slot="menu">
                        <el-button icon="iconfont icon-shangchuan" type="text" size="medium" :title="$t('Upload')" @click="upload(row,index,size,type)"></el-button>
                    </template>

                </avue-crud>
            </template>
        </basic-container>

    </div>
</template>
<style>
    .el-dialog__wrapper {
        transition-duration: 0.3s;
    }
    .othChe .el-checkbox__label {
        color: #409eff !important;
    }
    .dataicon i {
        display: none;
    }
</style>
<script>
    import { saleOrderDetailList} from "@/api/businessSalesOrd";
    import { TracingDropDownList } from "@/api/unified";
    export default {
        data() {
            return {
                businessnoinVal: "",
                businessnooutVal: "",
                beginTimeVal: "",
                endTimeVal: "",
                vendorSectionId: "",
                tableData: [],
                page: {
                    pageSize: 20,
                    pagerCount: 5,
                    total: 10,
                    pageCurrent: 1
                },
                tableOption: {
                    page: true,
                    addBtn: false,
                    refreshBtn: false,
                    emptyText: this.$t('No Data'),
                    menu: true,
                    menuTitle: this.$t('OPERATION'),
                    menuWidth: 140,
                    editBtn: false,
                    delBtn: false,
                    align: 'center',
                    header: false,
                    simplePage: false,
                    labelWidth: "33%",
                    expand: false,
                    rowKey: 'Id',
                    expandRowKeys: [],
                    column: [
                        {
                            label: this.$t('Business No In'),
                            prop: 'BusinessNoIn'
                        },
                        {
                            label: this.$t('Business No Out'),
                            prop: 'BusinessNoOut'
                        },
                        {
                            label: this.$t('STATUS'),
                            prop: 'Status'
                        },
                        {
                            label: this.$t('POProductCode'),
                            prop: 'Code'
                        },
                        {
                            label: this.$t('POProductName'),
                            prop: 'OutProductName'
                        },

                        {
                            label: this.$t('POBATCH'),
                            prop: 'Batch'

                        },
                        {
                            label: this.$t('InternalProductName'),
                            prop: 'ProductName'

                        },
                        {
                            label: this.$t('DATE'),
                            prop: 'OrderDate',
                            type: "date",
                            format: 'yyyy-MM-dd',
                            valueFormat: 'yyyy-MM-dd',
                            editDisabled: true
                        },
                        {
                            label: this.$t('Number'),
                            prop: 'UnitName'
                        },
                        {
                            label: this.$t('COLOR'),
                            prop: 'Color'
                        },
                        {
                            label: this.$t('Ingredient'),
                            prop: 'Ingredient'
                        },
                        {
                            label: this.$t('REMARK'),
                            prop: 'Remark'
                        }

                    ]
                }
            }
        },
        computed: {
            setData() {
                return {
                    tableOption: {
                        page: true,
                        addBtn: false,
                        refreshBtn: false,
                        emptyText: this.$t('No Data'),
                        menu: true,
                        menuTitle: this.$t('OPERATION'),
                        menuWidth: 140,
                        editBtn: false,
                        editBtnText: this.$t('Edit'),
                        editTitle: this.$t('Edit'),
                        delBtn: false,
                        align: 'center',
                        header: false,
                        simplePage: false,
                        labelWidth: "33%",
                        expand: false,
                        rowKey: 'Id',
                        expandRowKeys: [],
                        column: [
                            {
                                label: this.$t('Business No In'),
                                prop: 'BusinessNoIn'
                            },
                            {
                                label: this.$t('Business No Out'),
                                prop: 'BusinessNoOut'
                            },
                            {
                                label: this.$t('STATUS'),
                                prop: 'Status'
                            },
                            {
                                label: this.$t('POProductCode'),
                                prop: 'Code'
                            },
                            {
                                label: this.$t('POProductName'),
                                prop: 'OutProductName'
                            },

                            {
                                label: this.$t('POBATCH'),
                                prop: 'Batch'

                            },
                            {
                                label: this.$t('InternalProductName'),
                                prop: 'ProductName'
                           
                            },
                            {
                                label: this.$t('DATE'),
                                prop: 'OrderDate',
                                type: "date",
                                format: 'yyyy-MM-dd',
                                valueFormat: 'yyyy-MM-dd',  
                                editDisabled: true
                            },
                            {
                                label: this.$t('Number'),
                                prop: 'UnitName'
                            },
                            {
                                label: this.$t('COLOR'),
                                prop: 'Color'
                            },
                            {
                                label: this.$t('Ingredient'),
                                prop: 'Ingredient'
                            },
                            {
                                label: this.$t('REMARK'),
                                prop: 'Remark'
                            }
                            
                        ]
                    }
                }
            }
        },
        created() {
            this.getSaleOrderList();  
        },
        methods: {
            //设置表头行的样式
            tableHeaderColor() {
                return 'background-color:#838bb3!important;padding: 0!important;'

            }, //表格行央视
            rowStyle({ rowIndex }) {
                if (rowIndex % 2 === 0) {
                    return {
                        backgroundColor: '#F8F8F8'
                    }
                }
            },
            focusDiv(obj) {//搜索框获取焦点
                if (obj.currentTarget === undefined) {
                    obj.$el.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
                } else {
                    if (obj.currentTarget.parentElement.getAttribute('class').indexOf('suffix') !== -1) {
                        obj.currentTarget.parentElement.parentElement.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
                    } else {
                        obj.currentTarget.parentElement.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
                    }

                }
            },//搜索框失去焦点
            blurDiv(obj) {
                if (obj.currentTarget === undefined) {
                    if (obj.value === "") {
                        obj.$el.previousElementSibling.style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
                    }
                } else if (obj.currentTarget === null) {
                    if (this.fromVal.ids.length === 0) {
                        document.querySelector("#venSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
                    }
                } else {
                    if (obj.target.value == "") {
                        obj.currentTarget.parentElement.previousElementSibling.style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
                    }
                }
            },
            search() {
                this.getSaleOrderList();
            },
            currentChange(val) {
                this.page.currentPage = val
                this.getSaleOrderList();
            },
            sizeChange(pageSize) {
                this.page.pageCurrent = 1;
                this.page.pageSize = pageSize;
                this.list();
            },
             getSaleOrderList() {
                 saleOrderDetailList(this.page.currentPage, this.page.pageSize, this.businessnoinVal, this.businessnooutVal, this.beginTimeVal, this.endTimeVal).then(res => {
                    console.log('res', res);
                    this.tableData = res.data.SaleOrderDetails;
                    this.page.total = res.data.TotalCount;
                    this.page.pageSize = res.data.PageSize;
                }).catch(erro => {
                    console.log(erro)
                })
            },//上传
            upload(row) {
                this.vendorSectionId = row.VendorSectionId;
                console.log(row);
                //上传前判断是否有品牌商信息
                TracingDropDownList('OrderBrandTrackList', row.PurchaserOrderDetailId).then(res => {
                    console.log('OrderBrandTrackList',res);
                    this.brandList = res.data;
                    if (this.brandList.length > 0)
                    {
                        if (this.brandList.length == 1) {
                            this.$router.push({ path: '/pvh_factory/purchaseOrd', query: { orderId: row.Id, brandBusinessId: this.brandList[0].value, vendorSectionId: this.vendorSectionId } })
                        }
                        else {
                            this.PoDetailId = row.Id;
                            this.SOdialogTableVisible = true;
                        }
                    }
                    else
                    {
                        this.$message({
                            message: this.$t('NoBrandInfo'),
                            type: "warning"
                        });
                        return;
                    }
                });  
                //如果没有弹窗提示不能上传
                //如果只有一个上传跳转上传界面
                //如果多个弹出选择框
            }
        }
    }
</script>