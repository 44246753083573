<template>
	<div>
		<basic-container>
			<div>
				<span>{{$t('UserName')}}：</span>
				<el-input v-model="$route.query.userName" readonly placeholder="" style="width: 200px;height:50px;"></el-input>
				<el-input v-model="userId" placeholder="" style="width: 200px;height:50px;display:none"></el-input>
			</div>

			<!-- 树结构 -->
			<div style="marginTop: 20px;display: flex;">
				{{$t('Role')}}：
				<el-tree ref="treeData"
						 :data="data"
						 show-checkbox
						 node-key="id"
						 highlight-current
						 @check="clickCheck"
						 @setCheckedKeys="setCheckedKeys"
						 @node-collapse="nodeCollapse"
						 slot="reference"
						 :props="defaultProps">
				</el-tree>

			</div>
			<el-button type="info" @click="saveUserRole" style="marginTop: 20px;marginLeft:50px">{{$t('save')}}</el-button>

		</basic-container>
	</div>
</template>
<script>
    import { addUserRoleNew, userJurisdiction, userHasJurisdiction } from '@/api/roleToUser'
	export default {
		data() {
			return {
				userName: '',
				userId: '',
				tableData: [], //表结构
				check: {},
				checkedKeys: [],
				// 树结构数据
				data: [],
                roleIdList: [],
				defaultProps: {
					children: 'children',
					label: 'label'
				},
				// 表结构数据
			};
		},
		created() {
			//角色
			this.getUserJurisdiction();
			//this.userName = this.$route.query.userName;
			this.userId = this.$route.query.userId;
		},
		methods: {
			//保存用户角色
			saveUserRole() {
				let flag;
				flag = true;
				this.checkedKeys.forEach(item => {
					addUserRoleNew(this.userId, item).then(res => {
                        if (response.data.statusCode == 200 && !response.data.isError) {

                        }
                        else {
                            flag = false;
                        }
					})
				})
				if (flag) {
					this.$message({
						message: this.$t('OperationSuccess'),
						type: "success"
					});
				}
                else {
                    this.$message({ message: this.$t('OperationFailed'), type: "warning" });
                }
			},
			// 获取角色
			getUserJurisdiction() {
				userJurisdiction("").then(res => {
					this.data = res.data;
					userHasJurisdiction(this.userId).then(reshas => {
                        this.roleIdList = reshas.data;
                        console.log("hasrole", reshas.data);
                        var that = this;
                        that.$nextTick(function () {
							that.roleIdList.forEach((i, n) => {
                                console.log("item", i);
                                var node = that.$refs.treeData.getNode(i.id);
                                console.log("node", node);
                                if (node != null && node.isLeaf){
									that.$refs.treeData.setChecked(node, true);
									this.checkedKeys.push(i.id);
                                }
                            });
                        });
                    })                 					
				})
			},
			// 选中的节点
			clickCheck(currentObj, treeStatus) {
				this.str = currentObj.id
				this.check = currentObj               
				this.checkedKeys = treeStatus.checkedKeys
			},
			checkRow(checked, item) {
				this.checkId = eval(checked.join("+"))
			},
			radioRow(checked, item) {
				this.radioId = checked
			},
			setCheckedKeys(keys, leafOnly) {
			},
			nodeCollapse(key, value) {
			}
		}
	};
</script>
