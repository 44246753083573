<template>
    <div class="pvhFactory">
        <basic-container>
            <!--data 数据源 v-model：对象行 -->
            <avue-crud :data="dataObj" v-model="obj" :option="setData.option"
                       @row-save="rowSave" :page.sync="page" @current-change="currentChange"
                       @refresh-change="refreshChange">
                <template slot="menuRight">
                    <el-input v-model.trim="name" :placeholder="$t('Please enter a parameter name')" clearable size="small"
                              style="width:230px !important;marginRight: 5px;"></el-input>
                    <el-input v-model.trim="code" :placeholder="$t('Please enter the parameter number')" clearable size="small"
                              style="width:260px !important;marginRight: 5px;"></el-input>
                    <el-select v-model.trim="type" :placeholder="$t('Please enter the parameter type')" clearable size="small"
                               style="width:250px !important;marginRight: 5px;">
                        <el-option
                                v-for="item in dic"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </template>
            </avue-crud>
        </basic-container>
    </div>
</template>
<script>
    import {list, add} from "@/api/parameter";

    export default {
        data() {
            return {
                name: "",
                code: "",
                type: "",
                obj: {},
                page: {
                    currentPage: 1,
                    total: 0,
                    layout: "total,pager,prev, next",
                    background: false,
                    pageSize: 10
                },
                dic: [
                    {
                        label: '文本',
                        value: 0
                    },
                    // {
                    //     label: '枚举',
                    //     value: 1
                    // },
                    {
                        label: '数值',
                        value: 2
                    }
                ],
                dataObj: [],
            }
        },
        computed: {
            setData() {
                return {
                    option: {
                        page: true,
                        emptyText: this.$t('No Data'),
                        menuTitle: this.$t('OPERATION'),
                        menu: false,
                        menuWidth: 140,
                        align: 'center',
                        columnBtn: false,
                        simplePage: false,
                        editTitle: this.$t('Edit'),
                        addTitle: this.$t('Add'),
                        editBtn: false,
                        delBtn: false,
                        labelPosition: 'top',
                        menuAlign: 'center',
                        column: [
                            {
                                label: '参数编号',
                                prop: 'code',
                                rules: [{
                                    required: true,
                                    message: "请输入参数编号",
                                    trigger: "blur"
                                }]
                            },
                            {
                                label: '参数名称',
                                prop: 'name',
                                rules: [{
                                    required: true,
                                    message: "请输入参数名称",
                                    trigger: "blur"
                                }]
                            },
                            {
                                label: '参数类型',
                                type: "radio",
                                prop: 'type',
                                dicData: this.dic,
                                mock: {
                                    type: 'dic',
                                },
                                rules: [{
                                    required: true,
                                    message: "请输入参数类型",
                                    trigger: "blur"
                                }]
                            },
                        ]
                    }
                }
            }
        },
        created() {
            this.getList()
        },
        methods: {
            refreshChange() {
                this.getList()
            },
            //新增
            rowSave(row,done){
                add(row).then((res) => {
                    this.request.post(res)
                    this.$message({
                        type: "success",
                        message: res.data.msg,
                    });
                    done(row);
                })
            },
            getList() {
                const xx = {
                    pageNo: this.page.currentPage,
                    pageSize: this.page.pageSize,
                    total: this.page.total,
                    code: this.code,
                    name: this.name,
                    type: this.type,
                }
                list(xx).then(res => {
                    if (res.data.data == null) {
                        this.dataObj = [];
                        this.page.total = 0;
                    } else {
                        // debugger
                        this.page.total = res.data.data.total
                        this.dataObj = res.data.data.items
                    }
                    console.log(res, 'res')
                }).catch(erro => {
                    console.log(erro);
                })
            },
            currentChange(val) {
                this.page.currentPage = val
                this.getList()
                // this.$message.success('页码' + val)
            }
        }
    }
</script>
<style scoped>
</style>
