<template>
  <div class="pvhFactory">
    <basic-container>
      <!--data 数据源 v-model：对象行 -->
      <avue-crud :data="data" v-model="obj" :option="setData.option" @row-save="rowSave"
                 :page.sync="page" @current-change="currentChange"
                 @refresh-change="refreshChange">
        <template slot="menuRight">
          <el-input v-model.trim="name" placeholder="请输入名称" clearable size="small"
                    style="width:200px !important;marginRight: 5px;"></el-input>
          <el-input v-model.trim="code" placeholder="请输入编码" clearable size="small"
                    style="width:200px !important;marginRight: 5px;"></el-input>
        </template>
      </avue-crud>
    </basic-container>
  </div>
</template>
<script>
import {selectPageList, add} from "@/api/feedback";
export default {
  data() {
    return {
      name: "",
      code: "",
      obj: {},
      page: {
        currentPage: 1,
        total: 0,
        layout: "total,pager,prev, next",
        background: false,
        pageSize: 10
      },
      dic: [
        {
          label: '文本',
          value: 0
        }, {
          label: '数值',
          value: 1
        }
      ],
      data: [],
    }
  },
  computed: {
    setData() {
      return {
        option: {
          page: true,
          menu: false,//操作按钮
          editBtn: false,//修改按钮
          delBtn: false,//删除按钮
          align: 'center',
          columnBtn: false,//列冻结、隐藏按钮
          simplePage: false,
          menuAlign: 'center',
          column: [
            {
              label: '参数编号',
              prop: 'code',
              rules: [{
                required: true,
                message: "请输入参数编号",
                trigger: "blur"
              }]
            },
            {
              label: '参数名称',
              prop: 'name',
              rules: [{
                required: true,
                message: "请输入参数名称",
                trigger: "blur"
              }]
            },
            {
              label: '参数类型',
              type: "radio",
              prop: 'type',
              dicData: this.dic,
              mock: {
                type: 'dic',
              },
              rules: [{
                required: true,
                message: "请输入参数类型",
                trigger: "blur"
              }]
            },
          ]
        }
      }
    }
  },
  created() {
    this.getList()
  },
  methods: {
    refreshChange() {
      this.getList()
    },
    //新增
    rowSave(row,done) {
      add(row).then((res) => {
        this.request.post(res)
        this.$message({
          type: "success",
          message: res.data.msg,
        });
        done(row);
        this.getList();
      })
    },
    getList() {
      const xx = {
        pageNo: this.page.currentPage,
        pageSize: this.page.pageSize,
        total: this.page.total,
        code: this.code,
        name: this.name,
      }
      selectPageList(xx).then(res => {
        if (res.data.data == null) {
          this.data = [];
          this.page.total = 0;
        } else {
          // debugger
          this.page.total = res.data.data.total
          this.data = res.data.data.items
        }
        console.log(res, 'res')
      }).catch(erro => {
        console.log(erro);
      })
    },
    currentChange(val) {
      this.page.currentPage = val
      this.getList()
    }
  }
}
</script>
<style scoped>
</style>
