var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "home_container" } }, [
    _c(
      "div",
      { attrs: { id: "pvhinfo_container" } },
      [
        _c(
          "el-container",
          [
            _c(
              "el-header",
              { staticClass: "headerDiv", staticStyle: { padding: "0" } },
              [
                _c(
                  "el-row",
                  { staticStyle: { width: "100%" } },
                  [
                    _c(
                      "el-col",
                      {
                        staticStyle: { "text-align": "left" },
                        attrs: { span: 3 }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "background-color": "#15A2FF",
                              display: "flex",
                              "flex-direction": "column",
                              "margin-right": "1rem",
                              "border-radius": "5px"
                            }
                          },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-weight": "500",
                                  color: "#fff",
                                  "font-size": "14px",
                                  "margin-left": "5px"
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("Factories")))]
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "flex-direction": "row"
                                }
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "font-weight": "700",
                                      color: "#fff",
                                      "font-size": "18px",
                                      "margin-right": "5px",
                                      "margin-left": "5px"
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.topInfoData.factoriesVar))]
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    ),
                    _c(
                      "el-col",
                      {
                        staticStyle: { "text-align": "left" },
                        attrs: { span: 3 }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "background-color": "#15A2FF",
                              display: "flex",
                              "flex-direction": "column",
                              "margin-right": "1rem",
                              "border-radius": "5px"
                            }
                          },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-weight": "500",
                                  color: "#fff",
                                  "font-size": "14px",
                                  "margin-left": "5px",
                                  "padding-bottom": "3px"
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("Countries")))]
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "flex-direction": "row"
                                }
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "font-weight": "700",
                                      color: "#fff",
                                      "font-size": "18px",
                                      "margin-right": "5px",
                                      "margin-left": "5px"
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.topInfoData.countriesVar))]
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    ),
                    _c(
                      "el-col",
                      {
                        staticStyle: { "text-align": "left" },
                        attrs: { span: 4 }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "background-color": "#15A2FF",
                              display: "flex",
                              "flex-direction": "column",
                              "margin-right": "1rem",
                              "border-radius": "5px"
                            }
                          },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-weight": "500",
                                  color: "#fff",
                                  "font-size": "14px",
                                  "margin-left": "5px",
                                  "padding-bottom": "3px"
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("Orders")))]
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "flex-direction": "row"
                                }
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "font-weight": "700",
                                      color: "#fff",
                                      "font-size": "18px",
                                      "margin-right": "5px",
                                      "margin-left": "5px"
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.topInfoData.ordersVar))]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "font-weight": "500",
                                      color: "#fff",
                                      "font-size": "14px",
                                      "align-self": "flex-end"
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.$t("po")))]
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    ),
                    _c(
                      "el-col",
                      {
                        staticStyle: { "text-align": "left" },
                        attrs: { span: 4 }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "background-color": "#15A2FF",
                              display: "flex",
                              "flex-direction": "column",
                              "margin-right": "1rem",
                              "border-radius": "5px"
                            }
                          },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-weight": "500",
                                  color: "#fff",
                                  "font-size": "14px",
                                  "margin-left": "5px",
                                  "padding-bottom": "3px"
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("Garment Quantity")))]
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "flex-direction": "row"
                                }
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "font-weight": "700",
                                      color: "#fff",
                                      "font-size": "18px",
                                      "margin-right": "5px",
                                      "margin-left": "5px"
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.topInfoData.quantityVar))]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "font-weight": "500",
                                      color: "#fff",
                                      "font-size": "14px",
                                      "align-self": "flex-end"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t(_vm.topInfoData.unit_q))
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    ),
                    _c(
                      "el-col",
                      {
                        staticStyle: { "text-align": "left" },
                        attrs: { span: 3 }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "background-color": "#bfe8fd",
                              display: "flex",
                              "flex-direction": "column",
                              "margin-right": "1rem",
                              "border-radius": "5px"
                            }
                          },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-weight": "500",
                                  color: "#4e91f7",
                                  "font-size": "14px",
                                  "margin-left": "5px",
                                  "padding-bottom": "3px"
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("Raw fiber")))]
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "flex-direction": "row"
                                }
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "font-weight": "700",
                                      color: "#4e91f7",
                                      "font-size": "18px",
                                      "margin-right": "5px",
                                      "margin-left": "5px"
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.topInfoData.rawfiberVar))]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "font-weight": "500",
                                      color: "#4e91f7",
                                      "font-size": "14px",
                                      "align-self": "flex-end"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t(_vm.topInfoData.unit_g))
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    ),
                    _c(
                      "el-col",
                      {
                        staticStyle: { "text-align": "left" },
                        attrs: { span: 3 }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "background-color": "#bfe8fd",
                              display: "flex",
                              "flex-direction": "column",
                              "margin-right": "1rem",
                              "border-radius": "5px"
                            }
                          },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-weight": "500",
                                  color: "#4e91f7",
                                  "font-size": "14px",
                                  "margin-left": "5px",
                                  "padding-bottom": "3px"
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("Recyclable")))]
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "flex-direction": "row"
                                }
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "font-weight": "700",
                                      color: "#4e91f7",
                                      "font-size": "18px",
                                      "margin-right": "5px",
                                      "margin-left": "5px"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.topInfoData.recyclableVar)
                                    )
                                  ]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "font-weight": "500",
                                      color: "#4e91f7",
                                      "font-size": "14px",
                                      "align-self": "flex-end"
                                    }
                                  },
                                  [_vm._v("%")]
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    ),
                    _c(
                      "el-col",
                      {
                        staticStyle: { "text-align": "right" },
                        attrs: { span: 4 }
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "80%" },
                            attrs: {
                              size: "mini",
                              placeholder: _vm.$t("Year")
                            },
                            on: { change: _vm.yearselectChanged },
                            model: {
                              value: _vm.selectYear,
                              callback: function($$v) {
                                _vm.selectYear = $$v
                              },
                              expression: "selectYear"
                            }
                          },
                          _vm._l(_vm.dataYears, function(item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-row",
                  { staticStyle: { width: "100%" } },
                  [
                    _c(
                      "el-col",
                      {
                        staticStyle: { "padding-right": "20px" },
                        attrs: { span: 8 }
                      },
                      [
                        _c("div", { staticClass: "chartsDiv" }, [
                          _c(
                            "div",
                            {
                              staticClass: "chartsHeaderDiv",
                              staticStyle: {
                                display: "flex",
                                "justify-content": "space-between",
                                "align-items": "center"
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticStyle: { "text-align": "left" } },
                                [
                                  _c("img", {
                                    staticClass: "titImg",
                                    attrs: {
                                      src: "/img/button/angleMark.png",
                                      alt: ""
                                    }
                                  }),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "tit",
                                      staticStyle: {
                                        "text-overflow": "ellipsis",
                                        overflow: "hidden",
                                        "white-space": "nowrap",
                                        display: "inline-block",
                                        "vertical-align": "middle"
                                      },
                                      attrs: { title: _vm.$t("ORDER QUANTITY") }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("ORDER QUANTITY")))]
                                  ),
                                  _c("el-button", {
                                    staticClass: "btnDetail",
                                    attrs: {
                                      icon: "el-icon-s-order",
                                      type: "text",
                                      title: _vm.$t("Check the details")
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.jupmPoDetail()
                                      }
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "110px" },
                                      attrs: {
                                        size: "mini",
                                        placeholder: _vm.$t("Month")
                                      },
                                      on: { change: _vm.data1selectChanged },
                                      model: {
                                        value: _vm.charts01_dataVal,
                                        callback: function($$v) {
                                          _vm.charts01_dataVal = $$v
                                        },
                                        expression: "charts01_dataVal"
                                      }
                                    },
                                    _vm._l(_vm.dataMonths, function(item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.label,
                                          value: item.value
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "chartsBodyDiv" },
                            [
                              [
                                _c("div", {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading",
                                      value: _vm.loading01,
                                      expression: "loading01"
                                    }
                                  ],
                                  staticStyle: {
                                    width: "100%",
                                    height: "198px",
                                    padding: "10px 0 5px 0"
                                  },
                                  attrs: { id: "charts_01" }
                                })
                              ]
                            ],
                            2
                          )
                        ])
                      ]
                    ),
                    _c(
                      "el-col",
                      {
                        staticStyle: { "padding-right": "20px" },
                        attrs: { span: 8 }
                      },
                      [
                        _c("div", { staticClass: "chartsDiv" }, [
                          _c(
                            "div",
                            {
                              staticClass: "chartsHeaderDiv",
                              staticStyle: {
                                display: "flex",
                                "justify-content": "space-between",
                                "align-items": "center"
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticStyle: { "text-align": "left" } },
                                [
                                  _c("img", {
                                    staticClass: "titImg",
                                    attrs: {
                                      src: "/img/button/angleMark.png",
                                      alt: ""
                                    }
                                  }),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "tit",
                                      staticStyle: {
                                        "text-overflow": "ellipsis",
                                        overflow: "hidden",
                                        "white-space": "nowrap",
                                        display: "inline-block",
                                        "vertical-align": "middle"
                                      },
                                      attrs: {
                                        title: _vm.$t("TOTAL BRAND ANALYSIS")
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("TOTAL BRAND ANALYSIS"))
                                      )
                                    ]
                                  ),
                                  _c("el-button", {
                                    staticClass: "btnDetail",
                                    attrs: {
                                      icon: "el-icon-s-order",
                                      type: "text",
                                      title: _vm.$t("Check the details")
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.jupmPoDetail()
                                      }
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: {
                                        width: "65px",
                                        "margin-right": "5px"
                                      },
                                      attrs: {
                                        size: "mini",
                                        filterable: "",
                                        placeholder: _vm.$t("Brand")
                                      },
                                      on: { change: _vm.brandselectChanged },
                                      model: {
                                        value: _vm.charts02_brandVal,
                                        callback: function($$v) {
                                          _vm.charts02_brandVal = $$v
                                        },
                                        expression: "charts02_brandVal"
                                      }
                                    },
                                    _vm._l(_vm.brandData, function(item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.label,
                                          value: item.value
                                        }
                                      })
                                    }),
                                    1
                                  ),
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "70px" },
                                      attrs: {
                                        size: "mini",
                                        placeholder: _vm.$t("Month")
                                      },
                                      on: { change: _vm.data2selectChanged },
                                      model: {
                                        value: _vm.charts02_dataVal,
                                        callback: function($$v) {
                                          _vm.charts02_dataVal = $$v
                                        },
                                        expression: "charts02_dataVal"
                                      }
                                    },
                                    _vm._l(_vm.dataMonths, function(item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.label,
                                          value: item.value
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          ),
                          _c("div", { staticClass: "chartsBodyDiv" }, [
                            _c("div", {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.loading02,
                                  expression: "loading02"
                                }
                              ],
                              staticStyle: {
                                width: "100%",
                                height: "198px",
                                padding: "10px 0 5px 0"
                              },
                              attrs: { id: "charts_02" }
                            })
                          ])
                        ])
                      ]
                    ),
                    _c("el-col", { attrs: { span: 8 } }, [
                      _c("div", { staticClass: "chartsDiv" }, [
                        _c(
                          "div",
                          {
                            staticClass: "chartsHeaderDiv",
                            staticStyle: {
                              display: "flex",
                              "justify-content": "space-between",
                              "align-items": "center"
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticStyle: { "text-align": "left" } },
                              [
                                _c("img", {
                                  staticClass: "titImg",
                                  attrs: {
                                    src: "/img/button/angleMark.png",
                                    alt: ""
                                  }
                                }),
                                _c(
                                  "span",
                                  {
                                    staticClass: "tit",
                                    staticStyle: {
                                      "text-overflow": "ellipsis",
                                      overflow: "hidden",
                                      "white-space": "nowrap",
                                      display: "inline-block",
                                      "vertical-align": "middle"
                                    },
                                    attrs: {
                                      title: _vm.$t(
                                        "WRITE OFF QUANTITY OF RAW MATERIAL ORDER"
                                      )
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "WRITE OFF QUANTITY OF RAW MATERIAL ORDER"
                                        )
                                      )
                                    )
                                  ]
                                ),
                                _c("el-button", {
                                  staticClass: "btnDetail",
                                  attrs: {
                                    icon: "el-icon-s-order",
                                    type: "text",
                                    title: _vm.$t("Check the details")
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.jupmPoDetail()
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        ),
                        _c("div", { staticClass: "chartsBodyDiv" }, [
                          _c("div", {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loading03,
                                expression: "loading03"
                              }
                            ],
                            staticStyle: {
                              width: "100%",
                              height: "198px",
                              padding: "10px 0 5px 0"
                            },
                            attrs: { id: "charts_03" }
                          })
                        ])
                      ])
                    ])
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "el-container",
              [
                _c(
                  "el-row",
                  { staticStyle: { width: "100%" } },
                  [
                    _c(
                      "el-col",
                      {
                        staticStyle: { "padding-right": "20px" },
                        attrs: { span: 8 }
                      },
                      [
                        _c(
                          "el-row",
                          { staticStyle: { width: "100%" } },
                          [
                            _c("el-col", { attrs: { span: 24 } }, [
                              _c("div", { staticClass: "chartsDiv" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "chartsHeaderDiv",
                                    staticStyle: {
                                      display: "flex",
                                      "justify-content": "space-between",
                                      "align-items": "center"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "text-align": "left",
                                          width: "100%"
                                        }
                                      },
                                      [
                                        _c("img", {
                                          staticClass: "titImg",
                                          attrs: {
                                            src: "/img/button/angleMark.png",
                                            alt: ""
                                          }
                                        }),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "tit",
                                            staticStyle: {
                                              "text-overflow": "ellipsis",
                                              overflow: "hidden",
                                              "white-space": "nowrap",
                                              display: "inline-block",
                                              "vertical-align": "middle"
                                            },
                                            attrs: {
                                              title: _vm.$t(
                                                "SUPPLIER CERTIFICATION QUANTITY"
                                              )
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "SUPPLIER CERTIFICATION QUANTITY"
                                                )
                                              )
                                            )
                                          ]
                                        ),
                                        _c("el-button", {
                                          staticClass: "btnDetail",
                                          attrs: {
                                            icon: "el-icon-s-order",
                                            type: "text",
                                            title: _vm.$t("Check the details")
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.jupmSupplierCer()
                                            }
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                ),
                                _c("div", { staticClass: "chartsBodyDiv" }, [
                                  _c("div", {
                                    directives: [
                                      {
                                        name: "loading",
                                        rawName: "v-loading",
                                        value: _vm.loading04,
                                        expression: "loading04"
                                      }
                                    ],
                                    staticStyle: {
                                      width: "100%",
                                      height: "198px",
                                      padding: "10px 0 5px 0"
                                    },
                                    attrs: { id: "charts_04" }
                                  })
                                ])
                              ])
                            ])
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          { staticStyle: { width: "100%" } },
                          [
                            _c("el-col", { attrs: { span: 24 } }, [
                              _c("div", { staticClass: "chartsDiv" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "chartsHeaderDiv",
                                    staticStyle: {
                                      display: "flex",
                                      "justify-content": "space-between",
                                      "align-items": "center"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticStyle: { "text-align": "left" } },
                                      [
                                        _c("img", {
                                          staticClass: "titImg",
                                          attrs: {
                                            src: "/img/button/angleMark.png",
                                            alt: ""
                                          }
                                        }),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "tit",
                                            staticStyle: {
                                              "text-overflow": "ellipsis",
                                              overflow: "hidden",
                                              "white-space": "nowrap",
                                              display: "inline-block",
                                              "vertical-align": "middle"
                                            },
                                            attrs: { title: _vm.$t("TOP 10") }
                                          },
                                          [_vm._v(_vm._s(_vm.$t("TOP 10")))]
                                        ),
                                        _c("el-button", {
                                          staticClass: "btnDetail",
                                          attrs: {
                                            icon: "el-icon-s-order",
                                            type: "text",
                                            title: _vm.$t("Check the details")
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.jupmPoDetail()
                                            }
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "160px" },
                                            attrs: {
                                              size: "mini",
                                              filterable: "",
                                              placeholder: _vm.$t(
                                                "Classification"
                                              )
                                            },
                                            on: {
                                              change: _vm.charts05selectChanged
                                            },
                                            model: {
                                              value: _vm.charts05_Val,
                                              callback: function($$v) {
                                                _vm.charts05_Val = $$v
                                              },
                                              expression: "charts05_Val"
                                            }
                                          },
                                          _vm._l(_vm.charts05Data, function(
                                            item
                                          ) {
                                            return _c("el-option", {
                                              key: item.value,
                                              attrs: {
                                                label: item.label,
                                                value: item.value
                                              }
                                            })
                                          }),
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                ),
                                _c("div", { staticClass: "chartsBodyDiv" }, [
                                  _c("div", {
                                    directives: [
                                      {
                                        name: "loading",
                                        rawName: "v-loading",
                                        value: _vm.loading05,
                                        expression: "loading05"
                                      }
                                    ],
                                    staticStyle: {
                                      width: "100%",
                                      height: "198px",
                                      padding: "10px 0 5px 0"
                                    },
                                    attrs: { id: "charts_05" }
                                  })
                                ])
                              ])
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 16 } },
                      [
                        _c(
                          "el-main",
                          [
                            _c(
                              "el-row",
                              { staticStyle: { width: "100%" } },
                              [
                                _c("el-col", { attrs: { span: 24 } }, [
                                  _c("div", { staticClass: "chartsMapDiv" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "chartsHeaderMapDiv",
                                        staticStyle: {
                                          display: "flex",
                                          "justify-content": "space-between",
                                          "align-items": "center"
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "text-align": "left"
                                            }
                                          },
                                          [
                                            _c("img", {
                                              staticClass: "titImg",
                                              attrs: {
                                                src:
                                                  "/img/button/angleMark.png",
                                                alt: ""
                                              }
                                            }),
                                            _c(
                                              "span",
                                              {
                                                staticClass: "tit",
                                                staticStyle: {
                                                  "text-overflow": "ellipsis",
                                                  overflow: "hidden",
                                                  "white-space": "nowrap",
                                                  display: "inline-block",
                                                  "vertical-align": "middle"
                                                },
                                                attrs: {
                                                  title: _vm.$t(
                                                    "GLOBAL QUANTITATIVE INFORMATION STATISTICS"
                                                  )
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "GLOBAL QUANTITATIVE INFORMATION STATISTICS"
                                                    )
                                                  )
                                                )
                                              ]
                                            ),
                                            _c("el-button", {
                                              staticClass: "btnDetail",
                                              attrs: {
                                                icon: "el-icon-s-order",
                                                type: "text",
                                                title: _vm.$t(
                                                  "Check the details"
                                                )
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.jupmPoDetail()
                                                }
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "inline-block",
                                                  "margin-right": "10px",
                                                  cursor: "pointer"
                                                },
                                                attrs: { id: "mapBig" },
                                                on: { click: _vm.bigScr }
                                              },
                                              [
                                                _c("img", {
                                                  staticStyle: {
                                                    "vertical-align": "middle"
                                                  },
                                                  attrs: {
                                                    src: "/img/mapScr.png"
                                                  }
                                                })
                                              ]
                                            ),
                                            _c(
                                              "el-select",
                                              {
                                                staticStyle: { width: "120px" },
                                                attrs: {
                                                  size: "mini",
                                                  placeholder: _vm.$t(
                                                    "Supplier"
                                                  ),
                                                  clearable: ""
                                                },
                                                on: {
                                                  change: _vm.mapselectChanged
                                                },
                                                model: {
                                                  value: _vm.vendorVal,
                                                  callback: function($$v) {
                                                    _vm.vendorVal = $$v
                                                  },
                                                  expression: "vendorVal"
                                                }
                                              },
                                              _vm._l(_vm.vendorData, function(
                                                item
                                              ) {
                                                return _c("el-option", {
                                                  key: item.value,
                                                  attrs: {
                                                    label: item.label,
                                                    value: item.value
                                                  }
                                                })
                                              }),
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "chartsBodyMapDiv",
                                        attrs: { id: "mapDiv" }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              position: "fixed",
                                              top: "0",
                                              left: "0",
                                              width: "100%",
                                              background: "rgba(0,0,0,.8)",
                                              padding: "10px 40px",
                                              "z-index": "999",
                                              display: "none"
                                            },
                                            attrs: { id: "mapTopSear" }
                                          },
                                          [
                                            _c("el-autocomplete", {
                                              staticClass:
                                                "inline-input mapIpt",
                                              attrs: {
                                                "fetch-suggestions":
                                                  _vm.poSearch,
                                                placeholder: _vm.$t("PO"),
                                                "popper-append-to-body": false,
                                                clearable: ""
                                              },
                                              model: {
                                                value: _vm.POd,
                                                callback: function($$v) {
                                                  _vm.POd = $$v
                                                },
                                                expression: "POd"
                                              }
                                            }),
                                            _c("el-autocomplete", {
                                              staticClass:
                                                "inline-input mapIpt",
                                              attrs: {
                                                "fetch-suggestions":
                                                  _vm.pcSearch,
                                                placeholder: _vm.$t(
                                                  "ProductCode"
                                                ),
                                                "popper-append-to-body": false,
                                                clearable: ""
                                              },
                                              model: {
                                                value: _vm.proCode,
                                                callback: function($$v) {
                                                  _vm.proCode = $$v
                                                },
                                                expression: "proCode"
                                              }
                                            }),
                                            _c("el-autocomplete", {
                                              staticClass:
                                                "inline-input mapIpt",
                                              attrs: {
                                                "fetch-suggestions":
                                                  _vm.pnSearch,
                                                placeholder: _vm.$t(
                                                  "Product Name"
                                                ),
                                                "popper-append-to-body": false,
                                                clearable: ""
                                              },
                                              model: {
                                                value: _vm.proName,
                                                callback: function($$v) {
                                                  _vm.proName = $$v
                                                },
                                                expression: "proName"
                                              }
                                            }),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "inline-block",
                                                  cursor: "pointer"
                                                },
                                                on: { click: _vm.mapScSear }
                                              },
                                              [
                                                _c("img", {
                                                  staticStyle: {
                                                    "vertical-align": "middle"
                                                  },
                                                  attrs: { src: "/img/sr.png" }
                                                })
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              position: "fixed",
                                              top: "80px",
                                              right: "20px",
                                              background: "#fff",
                                              padding: "40px 20px",
                                              "z-index": "999",
                                              display: "none"
                                            },
                                            attrs: { id: "mapTwo" }
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                  "align-items": "center",
                                                  "justify-content":
                                                    "space-between"
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "center",
                                                      padding: "0 20px"
                                                    }
                                                  },
                                                  [
                                                    _c("img", {
                                                      staticStyle: {
                                                        width: "2rem",
                                                        "margin-bottom": "10px"
                                                      },
                                                      attrs: {
                                                        src: "/img/yf1.png"
                                                      }
                                                    }),
                                                    _c(
                                                      "p",
                                                      {
                                                        staticStyle: {
                                                          "margin-bottom":
                                                            "10px",
                                                          "font-size":
                                                            "1.25rem",
                                                          "font-weight": "700"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.topInfoData
                                                              .ordersVar
                                                          )
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "p",
                                                      {
                                                        staticStyle: {
                                                          "font-size": "0.7rem"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "TOTAL SYSTEM ORDERS"
                                                            )
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "center",
                                                      padding: "0 20px"
                                                    }
                                                  },
                                                  [
                                                    _c("img", {
                                                      staticStyle: {
                                                        width: "2rem",
                                                        "margin-bottom": "10px"
                                                      },
                                                      attrs: {
                                                        src: "/img/yf2.png"
                                                      }
                                                    }),
                                                    _c(
                                                      "p",
                                                      {
                                                        staticStyle: {
                                                          "margin-bottom":
                                                            "10px",
                                                          "font-size":
                                                            "1.25rem",
                                                          "font-weight": "700"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.topInfoData
                                                              .numOrdCom
                                                          )
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "p",
                                                      {
                                                        staticStyle: {
                                                          "font-size": "0.7rem"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t("COMPLETED")
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              position: "fixed",
                                              bottom: "80px",
                                              right: "20px",
                                              padding: "10px 0",
                                              "z-index": "999",
                                              display: "none",
                                              "min-width": "30%"
                                            },
                                            attrs: { id: "mapScrTot" }
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                  "justify-content":
                                                    "space-between"
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "background-color":
                                                        "#fff",
                                                      width: "100%",
                                                      padding: "5px",
                                                      display: "flex",
                                                      "justify-content":
                                                        "space-between",
                                                      "align-items": "center"
                                                    }
                                                  },
                                                  [
                                                    _vm.isRun
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              display:
                                                                "inline-block",
                                                              "margin-right":
                                                                "10px",
                                                              cursor: "pointer"
                                                            },
                                                            on: {
                                                              click: _vm.stop
                                                            }
                                                          },
                                                          [
                                                            _c("img", {
                                                              staticStyle: {
                                                                "vertical-align":
                                                                  "middle"
                                                              },
                                                              attrs: {
                                                                src:
                                                                  "/img/stop.png"
                                                              }
                                                            })
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    !_vm.isRun
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              display:
                                                                "inline-block",
                                                              "margin-right":
                                                                "10px",
                                                              cursor: "pointer"
                                                            },
                                                            on: {
                                                              click: _vm.start
                                                            }
                                                          },
                                                          [
                                                            _c("img", {
                                                              staticStyle: {
                                                                "vertical-align":
                                                                  "middle"
                                                              },
                                                              attrs: {
                                                                src:
                                                                  "/img/run.png"
                                                              }
                                                            })
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          "text-align": "left",
                                                          width:
                                                            "calc(100% - 20px)"
                                                        }
                                                      },
                                                      [
                                                        [
                                                          _c(
                                                            "el-carousel",
                                                            {
                                                              attrs: {
                                                                height: "28px",
                                                                direction:
                                                                  "vertical",
                                                                autoplay:
                                                                  _vm.isRun,
                                                                interval: 10000,
                                                                arrow: "never"
                                                              }
                                                            },
                                                            _vm._l(
                                                              _vm.mapArr,
                                                              function(
                                                                item,
                                                                index
                                                              ) {
                                                                return _c(
                                                                  "el-carousel-item",
                                                                  {
                                                                    key: index
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "h3",
                                                                      {
                                                                        staticClass:
                                                                          "medium",
                                                                        staticStyle: {
                                                                          "line-height":
                                                                            "28px",
                                                                          "font-size":
                                                                            "0.7rem",
                                                                          "text-overflow":
                                                                            "ellipsis",
                                                                          overflow:
                                                                            "hidden",
                                                                          "white-space":
                                                                            "nowrap",
                                                                          width:
                                                                            "100%"
                                                                        },
                                                                        attrs: {
                                                                          title:
                                                                            item.orderInfo
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            item.orderInfo
                                                                          )
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          )
                                                        ]
                                                      ],
                                                      2
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              position: "fixed",
                                              bottom: "25px",
                                              right: "20px",
                                              padding: "10px 0",
                                              "z-index": "999",
                                              display: "none",
                                              "min-width": "50%",
                                              background: "#fff"
                                            },
                                            attrs: { id: "mapTip" }
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                  "align-items": "center",
                                                  "justify-content":
                                                    "space-between",
                                                  padding: "0 20px"
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "margin-right": "20px"
                                                    }
                                                  },
                                                  [
                                                    _c("img", {
                                                      staticStyle: {
                                                        width: "1.25rem",
                                                        "vertical-align":
                                                          "middle",
                                                        "margin-right": "5px"
                                                      },
                                                      attrs: {
                                                        src:
                                                          "/img/report/Lx_Max.png"
                                                      }
                                                    }),
                                                    _vm._v(
                                                      "MULTIPLE PROCESSES\n                                                    "
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "margin-right": "20px"
                                                    }
                                                  },
                                                  [
                                                    _c("img", {
                                                      staticStyle: {
                                                        width: "1.25rem",
                                                        "vertical-align":
                                                          "middle",
                                                        "margin-right": "5px"
                                                      },
                                                      attrs: {
                                                        src:
                                                          "/img/report/L5_Max.png"
                                                      }
                                                    }),
                                                    _vm._v(
                                                      "RAW FIBER\n                                                    "
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "margin-right": "20px"
                                                    }
                                                  },
                                                  [
                                                    _c("img", {
                                                      staticStyle: {
                                                        width: "1.25rem",
                                                        "vertical-align":
                                                          "middle",
                                                        "margin-right": "5px"
                                                      },
                                                      attrs: {
                                                        src:
                                                          "/img/report/L4_Max.png"
                                                      }
                                                    }),
                                                    _vm._v(
                                                      "MATERIAL PROCESS\n                                                    "
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "margin-right": "20px"
                                                    }
                                                  },
                                                  [
                                                    _c("img", {
                                                      staticStyle: {
                                                        width: "1.25rem",
                                                        "vertical-align":
                                                          "middle",
                                                        "margin-right": "5px"
                                                      },
                                                      attrs: {
                                                        src:
                                                          "/img/report/L3_Max.png"
                                                      }
                                                    }),
                                                    _vm._v(
                                                      "YARN\n                                                    "
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "margin-right": "20px"
                                                    }
                                                  },
                                                  [
                                                    _c("img", {
                                                      staticStyle: {
                                                        width: "1.25rem",
                                                        "vertical-align":
                                                          "middle",
                                                        "margin-right": "5px"
                                                      },
                                                      attrs: {
                                                        src:
                                                          "/img/report/L2_Max.png"
                                                      }
                                                    }),
                                                    _vm._v(
                                                      "FABRIC\n                                                    "
                                                    )
                                                  ]
                                                ),
                                                _c("div", [
                                                  _c("img", {
                                                    staticStyle: {
                                                      width: "1.25rem",
                                                      "vertical-align":
                                                        "middle",
                                                      "margin-right": "5px"
                                                    },
                                                    attrs: {
                                                      src:
                                                        "/img/report/L1_Max.png"
                                                    }
                                                  }),
                                                  _vm._v(
                                                    "GARMENT\n                                                    "
                                                  )
                                                ])
                                              ]
                                            )
                                          ]
                                        ),
                                        _c("div", {
                                          staticStyle: {
                                            width: "100%",
                                            height: "100%"
                                          },
                                          attrs: { id: "map" }
                                        })
                                      ]
                                    )
                                  ])
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-footer",
                          {
                            staticStyle: {
                              padding: "0 0px !important",
                              height: "auto"
                            }
                          },
                          [
                            _c(
                              "el-row",
                              { staticStyle: { width: "100%" } },
                              [
                                _c(
                                  "el-col",
                                  {
                                    staticStyle: { height: "40px" },
                                    attrs: { span: 19 }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "background-color": "#fff",
                                          width: "100%",
                                          padding: "5px",
                                          display: "flex",
                                          "justify-content": "space-between",
                                          "align-items": "center",
                                          border: "2px solid #15A2FF"
                                        }
                                      },
                                      [
                                        _vm.isRun
                                          ? _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "inline-block",
                                                  "margin-right": "10px",
                                                  cursor: "pointer"
                                                },
                                                on: { click: _vm.stop }
                                              },
                                              [
                                                _c("img", {
                                                  staticStyle: {
                                                    "vertical-align": "middle"
                                                  },
                                                  attrs: {
                                                    src: "/img/stop.png"
                                                  }
                                                })
                                              ]
                                            )
                                          : _vm._e(),
                                        !_vm.isRun
                                          ? _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "inline-block",
                                                  "margin-right": "10px",
                                                  cursor: "pointer"
                                                },
                                                on: { click: _vm.start }
                                              },
                                              [
                                                _c("img", {
                                                  staticStyle: {
                                                    "vertical-align": "middle"
                                                  },
                                                  attrs: { src: "/img/run.png" }
                                                })
                                              ]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "text-align": "left",
                                              width: "calc(100% - 20px)"
                                            }
                                          },
                                          [
                                            [
                                              _c(
                                                "el-carousel",
                                                {
                                                  attrs: {
                                                    height: "28px",
                                                    direction: "vertical",
                                                    autoplay: _vm.isRun,
                                                    interval: 10000,
                                                    arrow: "never"
                                                  }
                                                },
                                                _vm._l(_vm.mapArr, function(
                                                  item,
                                                  index
                                                ) {
                                                  return _c(
                                                    "el-carousel-item",
                                                    { key: index },
                                                    [
                                                      _c(
                                                        "h3",
                                                        {
                                                          staticClass: "medium",
                                                          staticStyle: {
                                                            "line-height":
                                                              "28px",
                                                            "font-size":
                                                              "0.7rem",
                                                            "text-overflow":
                                                              "ellipsis",
                                                            overflow: "hidden",
                                                            "white-space":
                                                              "nowrap",
                                                            width: "100%"
                                                          },
                                                          attrs: {
                                                            title:
                                                              item.orderInfo
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.orderInfo
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                }),
                                                1
                                              )
                                            ]
                                          ],
                                          2
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _c(
                                  "el-col",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      height: "40px"
                                    },
                                    attrs: { span: 5 }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "background-color": "#15A2FF",
                                          display: "flex",
                                          "flex-direction": "column",
                                          "margin-right": "1rem",
                                          width: "100%"
                                        }
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "font-weight": "500",
                                              color: "#fff",
                                              "font-size": "14px",
                                              "margin-left": "1rem"
                                            }
                                          },
                                          [_vm._v(_vm._s(_vm.$t("WIP")))]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              display: "flex",
                                              "flex-direction": "row"
                                            }
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "font-weight": "700",
                                                  color: "#fff",
                                                  "font-size": "18px",
                                                  "margin-right": "1rem",
                                                  "margin-left": "1rem"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.topInfoData.wipPo)
                                                )
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "font-weight": "500",
                                                  color: "#fff",
                                                  "font-size": "14px"
                                                }
                                              },
                                              [_vm._v(_vm._s(_vm.$t("po")))]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }